


import { Vue, Component, Prop } from 'vue-property-decorator';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import TranslitHelper from '@/_helpers/translit.helper';

const translitHelper = new TranslitHelper();

@Component({
  components: {
    Person,
  }
})
export default class SharedContactCard extends Vue {

  @Prop({ type: Object, default: (): TEvent => null })
  public readonly event: TEvent;

  @Prop({ type: Object, default: (): TContact => null })
  public readonly sharedContact: TContact;

  @Prop({ type: Boolean, default: false })
  public readonly isViewingSelf: boolean;

  public get eventHref(): string {
    if (!this.event) {
      return '';
    }
    const eventSlug: string = translitHelper.getTransliterated(this.event.title) + '-' + this.event.id;
    return '/' + this.$i18n.locale + '/events/' + eventSlug + '/';
  }

  public get requestMeetingHref(): string {
    if (!this.event) {
      return '';
    }
    return '/' + this.$i18n.locale + '/events/' + this.event.id + '/contact/' + this.sharedContact.id + '/#request-a-meeting';
  }

  public get eventName(): string {
    return (this.event && this.event.title) || '';
  }

  public get eventStart(): string {
    if (!this.event || !this.event.date_start) {
      return '';
    }
    const start: Date = new Date(this.event.date_start);
    return this.$moment(start).format('DD MMM YYYY');
  }

  public get eventEnd(): string {
    if (!this.event || !this.event.date_end) {
      return '';
    }
    const end: Date = new Date(this.event.date_end);
    return this.$moment(end).format('DD MMM YYYY');
  }

  public get eventDates(): string {
    let eventStartPrepared = this.eventStart;
    const isSame = this.eventStart === this.eventEnd;
    if (!isSame) {
      const end: Date = new Date(this.event.date_end);
      const endYear = this.$moment(end).format('YYYY');
      const endMonth = this.$moment(end).format('MMM');
      eventStartPrepared = this.eventStart
        .replace(endYear, '')
        .replace(endMonth, '')
        .trim();
    }
    return [
      isSame ? null : eventStartPrepared,
      this.eventEnd,
    ]
      .filter(x => !!x)
      .join(' — ');
  }

};
